import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Comingsoon from '../src/Comingsoon';
import CancellationPolicy from "../src/pages/Cancellationpolicy";
import RefundPolicy from "../src/pages/RefundPolicy";
import TermsAndConditions from "../src/pages/TermsAndConditions";
import "./App.css";
import Footersection from '../src/Footersection';
function App() {
  return (
    <div className="Apps">
      <header className="App-headers">
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <Link class="nav-link active" aria-current="page" to="/">Home</Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link active" to="/Cancellationpolicy">Cancellationpolicy</Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link active" to="/RefundPolicy">RefundPolicy</Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link active" to="/TermsAndConditions">TermsAndConditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Comingsoon />} />
          <Route path="/Cancellationpolicy" element={<CancellationPolicy />} />
          <Route path="/RefundPolicy" element={<RefundPolicy />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        </Routes>
      </main>
      <Footersection />
    </div>
  );
}
export default App;
