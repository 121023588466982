import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footersection = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-left">
                    <address>
                        126, Jawaharlal Nehru Road, <br />
                        Senthil Nagar, <br />
                        Arumbakkam – 600 106
                    </address>
                    <div className="footer-style">
                        <ul className="footer-style-link">
                            <li><Link to="/Cancellationpolicy">Cancellation Policy</Link></li>
                            <li><Link to="/RefundPolicy">Refund Policy</Link></li>
                            <li><Link to="/TermsAndConditions">Terms and Conditions</Link></li>
                        </ul>
                    </div>
                </div>

            </div>
        </footer>
    );
};

export default Footersection;
