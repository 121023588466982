import React from 'react';

const styles = {
    container: {
        padding: '20px',
        margin: 'auto',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        backgroundColor: '#ffffff', // White background color
        borderRadius: '8px', // Optional: Adds rounded corners
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional: Adds a subtle shadow for depth
    },
    heading2: {
        textAlign: 'center',
        color: '#1A73E8',
        marginTop: "90px",
        // marginBottom: '20px',
    },
    heading3: {
        textAlign: 'left', // Align to start
        color: '#333',
        marginLeft: "60px",
        fontWeight: 'bold', // Ensure h3 is left-aligned
        // Dark gray for subheadings
    },
    paragraph: {
        textAlign: 'justify',
        marginBottom: '15px',
        marginLeft: "60px", // Ensure h3 is left-aligned
    },
    strong: {
        color: '#000', // Black for emphasis
    },
    section: {
        marginBottom: '20px', // Spacing between sections
    },
};

const TermsAndConditions = () => {
    return (
        <div>
            <h2 style={styles.heading2}>Terms and Conditions</h2>

            <div style={styles.section}>
                <h3 style={styles.heading3}>1. Introduction</h3>
                <p style={styles.paragraph}>
                    These terms and conditions govern the use of services provided by Kanaiyaazhi. By signing a contract with us or using our services, you agree to comply with these terms and conditions.
                </p>
            </div>


            <div style={styles.section}>
                <h3 style={styles.heading3}>2. Services Provided
                </h3>
                <p style={styles.paragraph}>
                    Kanaiyaazhi offers a comprehensive range of wedding and event planning services, including but not limited to venue selection, catering, decoration, photography, makeup, and entertainment.
                </p>

            </div>

            <div style={styles.section}>
                <h3 style={styles.heading3}>3. Client Responsibilities</h3>
                <p style={styles.paragraph}>
                    <strong style={styles.strong}>Accurate Information: </strong>  You must provide accurate and complete information about your preferences, guest lists, and other relevant details to ensure that we can deliver the best possible service.
                </p>
                <p style={styles.paragraph}>
                    <strong style={styles.strong}>Timely Decisions: </strong> You are responsible for making decisions in a timely manner, including approving designs, choosing vendors, and confirming event details.
                </p>
                <p style={styles.paragraph}>
                    <strong style={styles.strong}>Payment:</strong> You agree to make payments as outlined in the contract. Failure to make timely payments may result in delays or cancellation of services.
                </p>


            </div>

            <div style={styles.section}>
                <h3 style={styles.heading3}>4. 4. Payment Terms
                </h3>
                <p style={styles.paragraph}>
                    <strong style={styles.strong}>Deposit: </strong> A non-refundable deposit of 30% of the total contract value is required to secure your booking.

                </p>
                <p style={styles.paragraph}>
                    <strong style={styles.strong}>Installments: </strong> The remaining balance can be paid in installments as outlined in the contract.

                </p>
                <p style={styles.paragraph}>
                    <strong style={styles.strong}>Final Payment: </strong> The final payment must be made no later than 15 days before the event date.
                </p>


            </div>

            <div style={styles.section}>
                <h3 style={styles.heading3}>5. Intellectual Property</h3>
                <p style={styles.paragraph}>
                    All designs, concepts, and materials created by Kanaiyaazhi are the intellectual property of the company. You may not use or reproduce these materials for any purpose other than the event for which they were created.                </p>
            </div>

            <div style={styles.section}>
                <h3 style={styles.heading3}>6. Liability
                </h3>
                <p style={styles.paragraph}>
                    <strong style={styles.strong}>Limitation of Liability:</strong>  Kanaiyaazhi's liability for any damages arising from the event planning services is limited to the total amount paid by the client.

                </p>
                <p style={styles.paragraph}>
                    <strong style={styles.strong}>Third-Party Vendors:</strong> Kanaiyaazhi is not liable for the actions or failures of third-party vendors, including venues, caterers, and entertainers.

                </p>
            </div>

            <div style={styles.section}>
                <h3 style={styles.heading3}>7. Privacy and Confidentiality

                </h3>
                <p style={styles.paragraph}>
                    We respect your privacy and will take all necessary steps to protect your personal information. We will not share your details with any third party without your explicit consent, except where required by law.
                </p>
            </div>

            <div style={styles.section}>
                <h3 style={styles.heading3}>8. Amendments

                </h3>
                <p style={styles.paragraph}>
                    Kanaiyaazhi reserves the right to amend these terms and conditions at any time. Clients will be notified of any changes, and continued use of our services will be deemed as acceptance of the updated terms.
                </p>

            </div>

            <div style={styles.section}>
                <h3 style={styles.heading3}>9. Dispute Resolution
                </h3>
                <p style={styles.paragraph}>
                    In the event of a dispute, both parties agree to attempt to resolve the matter through negotiation or mediation before pursuing legal action.
                </p>
            </div>

            <div style={styles.section}>
                <h3 style={styles.heading3}>10. Governing Law
                </h3>
                <p style={styles.paragraph}>
                    These terms and conditions are governed by the laws of India, and any disputes will be subject to the exclusive jurisdiction of the courts in Chennai.
                </p>
            </div>
        </div>

    );
};

export default TermsAndConditions;
