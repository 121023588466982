import React from "react";
import styled, { keyframes } from "styled-components";
import background from "../src/images/background.jpeg"; // Adjust the path based on your file structure

// Keyframes for the wave animation
const waviy = keyframes`
  0%, 40%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
`;

// Styled component for the body
const Body = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw; /* Adjusted width for mobile responsiveness */
  min-height: 100vh;
  text-align: center;
  color: #fff; /* Ensure text is visible on the background */
`;

// Styled component for the waviy class with margin-top added
const Waviy = styled.div`
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
  font-size: 60px;
  margin-top: 50vh; /* Updated margin-top */

  @media (max-width: 768px) {
    font-size: 40px; /* Adjust font-size for tablets */
    margin-top: 30vh; /* Adjust margin-top for tablets */
  }

  @media (max-width: 480px) {
    font-size: 30px; /* Adjust font-size for mobile devices */
    margin-top: 45vh; /* Adjust margin-top for mobile devices */
  }
`;

// Styled component for the span within waviy
const WaviySpan = styled.span`
  font-family: "Alfa Slab One", cursive;
  position: relative;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  animation: ${waviy} 2.5s infinite;
  animation-delay: ${({ index }) => `calc(0.2s * ${index})`};
`;

// Styled component for the social media icons container
const SocialMediaIcons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 480px) {
    gap: 10px; /* Adjust gap for mobile devices */
  }
`;

// Styled component for individual social media icons
const IconWrapper = styled.a`
  color: #fff;
  font-size: 30px;
  transition: color 0.3s;

  &:hover {
    color: #1da1f2; /* Twitter blue */
  }

  &:nth-child(2):hover {
    color: #4267b2; /* Facebook blue */
  }

  &:nth-child(3):hover {
    color: #e1306c; /* Instagram pink */
  }

  @media (max-width: 480px) {
    font-size: 24px; /* Adjust font-size for mobile devices */
  }
`;

// Styled component for the email input container
const EmailContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (max-width: 480px) {
    flex-direction: column; /* Stack elements for mobile devices */
    gap: 5px; /* Adjust gap for mobile devices */
  }
`;

// Styled component for the email input
const EmailInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  outline: none;

  @media (max-width: 480px) {
    width: 80%; /* Adjust width for mobile devices */
  }
`;

// Styled component for the submit button
const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #1da1f2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0d8bdb;
  }

  @media (max-width: 480px) {
    width: 80%; /* Adjust width for mobile devices */
  }
`;

const Heading = styled.h1`
  position: absolute;
  font-size: xxx-large; /* Adjust this value as needed */
  color: #fff; /* Ensure the text color is visible on the background */
  font-family: "Alfa Slab One", cursive;
  font-weight: 60;
`;

// Main functional component
const ComingSoon = () => {
  const text = "Coming Soon";
  return (
    <Body>
      <Heading>Kanaa App</Heading>
      <Waviy>
        {text.split("").map((char, index) => (
          <WaviySpan key={index} index={index + 1} className={index == 6 ? "backgroundimage" : ""}>
            {char}
          </WaviySpan>
        ))}
      </Waviy>
    </Body >
  );
};

export default ComingSoon;
