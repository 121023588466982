import React from 'react';

const styles = {
    container: {
        padding: '20px',
        margin: 'auto',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        backgroundColor: '#ffffff', // White background color
        borderRadius: '8px', // Optional: Adds rounded corners
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional: Adds a subtle shadow for depth
    },
    heading2: {
        textAlign: 'center',
        color: '#1A73E8', // Adjust color as needed
        marginTop: "90px",
    },
    heading3: {
        textAlign: 'left', // Center align the h3 headings
        color: '#333', // Dark gray for subheadings
        marginTop: '20px',
        marginBottom: '10px',
        fontWeight: 'bold',
        marginLeft: "60px", // Ensure h3 is left-aligned
        // Optional: Bold font weight for better visibility
    },
    paragraph: {
        textAlign: 'justify',
        marginBottom: '15px',
        marginLeft: "60px", // Ensure h3 is left-aligned

    },
    strong: {
        color: '#000', // Black for emphasis
    },
};

const RefundPolicy = () => {
    return (
        <div>
            <h2 style={styles.heading2}>Refund Policy</h2>

            <h3 style={styles.heading3}>1. Introduction
            </h3>
            <p style={styles.paragraph}>
                This refund policy outlines the conditions under which you may request a refund from Kanaiyaazhi for wedding planning services.
            </p>

            <h3 style={styles.heading3}>2. Refund Eligibility</h3>
            <p style={styles.paragraph}>
                <strong style={styles.strong}>Cancellation-Based Refunds:</strong> Refunds are provided based on the cancellation policy detailed above.
            </p>
            <p style={styles.paragraph}>
                <strong style={styles.strong}>Service Discrepancies:</strong> If you believe that the services provided do not match the contract or the quality promised, you must notify us within 7 days of the event. We will investigate the matter and, if we find that the services were indeed not up to standard, we will offer a partial or full refund depending on the severity of the issue.
            </p>
            <p style={styles.paragraph}>
                <strong style={styles.strong}>Non-Refundable Items:</strong> Deposits or payments made for third-party vendors (such as venues, caterers, decorators) are subject to the vendor's refund policies and may not be refundable through Kanaiyaazhi.
            </p>
            <h3 style={styles.heading3}>3. Refund Process
            </h3>
            <p style={styles.paragraph}>
                <strong style={styles.strong}>Requesting a Refund:</strong> To request a refund, please contact our customer service team with your contract details and the reason for the refund request.
            </p>
            <p style={styles.paragraph}>
                <strong style={styles.strong}>Refund Timelines:</strong> Approved refunds will be processed within 14 business days of the approval date. Refunds will be issued through the original payment method unless otherwise agreed upon.

            </p>
        </div>
    );
};

export default RefundPolicy;
