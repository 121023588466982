import React from 'react';

const styles = {
    container: {
        padding: '20px',
        margin: 'auto',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        backgroundColor: '#ffffff', // White background color
        borderRadius: '8px', // Optional: Adds rounded corners
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional: Adds a subtle shadow for depth
        maxWidth: '800px', // Limit the maximum width for better readability
        boxSizing: 'border-box', // Ensure padding and border are included in the element's total width and height
    },
    heading2: {
        textAlign: 'center',
        color: '#1A73E8', // Adjust color as needed
        marginTop: "90px",
    },
    heading3: {
        textAlign: 'left',
        marginLeft: "60px", // Ensure h3 is left-aligned
        color: '#333', // Dark gray for subheadings
        marginTop: '20px',
        marginBottom: '10px',
        fontWeight: 'bold', // Optional: Bold font weight for better visibility
    },
    paragraph: {
        textAlign: 'justify',
        marginBottom: '15px',
        marginLeft: "60px", // Ensure h3 is left-aligned

    },
    strong: {
        color: '#000', // Black for emphasis
    },
};

const CancellationPolicy = () => {
    return (
        <div>
            <h2 style={styles.heading2}>Cancellation Policy</h2>

            <h3 style={styles.heading3}>1. Introduction</h3>
            <p style={styles.paragraph}>
            This cancellation policy outlines the terms under which you may cancel your contract with Kanaiyaazhi. We strive to be as accommodating as possible but must also account for the time, resources, and effort that go into planning your wedding or event.            </p>

            <h3 style={styles.heading3}>2. Organizer Cancellation:</h3>
            <p style={styles.paragraph}>
            Cancellation Prior to 90 Days of Event Date: If you wish to cancel the event or wedding planning services 90 days or more before the event date, you will be entitled to a full refund minus a 10% administrative fee.</p>
            <p style={styles.paragraph}>
                <strong style={styles.strong}>Cancellation Between 60-89 Days of Event Date:</strong> Cancellations made between 60 and 89 days prior to the event date will be subject to a cancellation fee equal to 25% of the total contract value.
            </p>
            <p style={styles.paragraph}>
                <strong style={styles.strong}>Cancellation Between 30-59 Days of Event Date:</strong> Cancellations made between 60 and 89 days prior to the event date will be subject to a cancellation fee equal to 25% of the total contract value.
            </p>

            <p style={styles.paragraph}>
                <strong style={styles.strong}> Cancellation Within 29 Days of Event Date:</strong>  If you cancel within 29 days of the event date, no refund will be issued, and 100% of the contract value will be charged as a cancellation fee.
            </p>
            
            <p style={styles.paragraph}>
                <strong style={styles.strong}> Cancellation Due to Force Majeure:</strong>  If the event is canceled due to unforeseen circumstances such as natural disasters, government regulations, or pandemics, we will work with you to reschedule the event at no additional cost. However, if rescheduling is not possible, the above cancellation charges will apply.
            </p>
            <h3 style={styles.heading3}>3. Cancellation by Kanaiyaazhi
            </h3>
            <p style={styles.paragraph}>
            In the rare case that Kanaiyaazhi must cancel the event due to unforeseen circumstances, we will provide a full refund of any payments made by the client. Additionally, we will assist in finding alternative service providers if necessary.            </p>
        </div>
    );
};

export default CancellationPolicy;
